import React from "react"
import Typography from "@material-ui/core/Typography"
import Layout from "../components/layout"

export default function MissingPage() {
  return (
    <Layout>
      <Typography align="center" color="inherit" variant="h2">
        Fumble!
      </Typography>
      <Typography align="center" color="inherit" variant="h4">
        Page Does Not Exist :(
      </Typography>
    </Layout>
  )
}
